"use client";

import { captureException } from "@sentry/browser";
import Error from "next/error";
import Image from "next/image";
import { useEffect } from "react";
import background500 from "src/assets/images/500_background.png";

const GlobalError = ({ error }: { error?: Error }) => {
	useEffect(() => {
		if (error) {
			captureException(error);
			// Ran into issues with refresh loops in subdomain testing. Disabling for now.
			/*
			if ((error as any)?.name === "ChunkLoadError") {
				// This is a chunk load error, we should reload the page
				const timeout = setTimeout(() => {
					window.location.reload();
				}, 500);
				return () => {
					clearTimeout(timeout);
				};
			}
            */
		}
		return;
	}, [error]);

	return (
		<html>
			<body>
				<Image
					src={background500}
					alt="500 Image"
					style={{
						position: "absolute",
						width: "100%",
						height: "100%",
						objectFit: "cover",
					}}
				/>
				<div
					style={{
						position: "absolute",
						bottom: 0,
						width: "100%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						paddingBottom: 20,
					}}
				>
					<span
						style={{
							color: "white",
							fontWeight: "bold",
							fontSize: "36px",
						}}
					>
						Something went wrong!
					</span>
					<span
						style={{
							color: "white",
							fontSize: "24px",
						}}
					>
						We ran into an error
					</span>
				</div>
			</body>
		</html>
	);
};
export default GlobalError;
